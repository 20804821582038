
.horizontal-card{
	width: 70%;
	
	display: grid;
	grid-template-columns: repeat(9,1fr);
	grid-template-rows: repeat(2, auto-fill);
	row-gap:  10rem;

	grid-template-areas:
	"i i h h s s s s -"
	"i i h h s s s s -"
  }

  .horizontal-card>*{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  }

  .horizontal-card>.img-div{
	grid-area: i;
  }
  .horizontal-card img{
	width: 50%;
  }

  .horizontal-card> header{
	grid-area: h;
	align-items: baseline;
	font: small-caps 600 2rem  var(--app-font-family);
  }
  .horizontal-card>section{
	grid-area: s;
	align-items: baseline;
	justify-content: space-evenly;
  }

/*****
Some common breakpoints for devices with different screen sizes are:
320px — 480px: Mobile devices
481px — 768px: iPads, Tablets
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV
****/

  @media (max-width: 599px) {
	.horizontal-card{
		width: 85%;
		row-gap: .5rem;
		grid-template-areas:
		  "- i i i h h h h h"
		  "- i i i s s s s s "
	}
	.horizontal-card> header{
		font-size: 1.7rem ;
		justify-content: flex-end;
	  }
	  .horizontal-card> section{
		font-size: .8rem;
	  }
	  .horizontal-card img{
		width: 65%;
	  }
  }

/** low res devices**/
@media (min-width: 600px) and (max-width: 768px) {
	.horizontal-card{
		width: 80%;
		row-gap: 0;
		grid-template-areas:
		  "i i i - h h h h h"
		  "i i i - s s s s s "
	}
	.horizontal-card> header{
		font-size: 1.8rem ;
	  }
}

/** Small Computer screens**/
@media (min-width: 769px) and (max-width: 1024px) {
	.horizontal-card{
		width: 80%;
		row-gap: 0;
		column-gap: .5rem;
		grid-template-areas:
		"- i i i h h h h h"
		"- i i i s s s s s "
	}
	.horizontal-card> header{
		font-size: 1.8rem ;
		justify-content: flex-end;
	  }
	  .horizontal-card img{
		width: 50%;
	  }
}
/** Small Computer screens**/
@media (min-width: 1025px) and (max-width: 1200px) {
	.horizontal-card{
		row-gap: 0;
		width: 80%;
		column-gap: .5rem;
		grid-template-areas:
			"i i h h s s s s -"
			"i i h h s s s s -"
	}
	.horizontal-card> header{
		font-size: 1.7rem ;
	  }
}

/** New  Computer /laptop screens**/
@media (min-width: 1201px) and (max-width: 1468px) {
	.horizontal-card{
		width: 80%;
		column-gap: .5rem;
	}
}

/** *huge devices*/
@media (min-width: 1800px) {
	.horizontal-card> header{
		font-size: 2.5rem ;
	  }
}