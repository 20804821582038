@import url('./zigzag-container.css');
@import url('./horizontal-card.css');


.main-container {
	padding: 10vh 1rem;

	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-template-rows: repeat(2, auto-fill);
	row-gap: 3vh;
}

.container-description {
	grid-column: 1 / 5;
	padding: 1em 1em;
	height: 5em;
	border-radius: 1em;
	box-shadow: .5vh .5vh 1vh .5vh var(--color-primary);
}

.container-description a {
	padding: .25rem;
	text-decoration: none;
	font-weight: 500;
}

.inner-container {
	grid-column: 1 / -1;
	padding: 1vh 1vw;
}



/** Phones **/
@media (max-width: 599px) {
	.main-container {
		padding-left: .75rem;
		padding-right: .75rem;
	}
	.inner-container {
		padding: 5vh 0;
	}
	.container-description {
		grid-column: 1 / -1;
		height: 6rem;
	}
}

/** low res devices**/
@media (min-width: 600px) and (max-width: 768px) {
	.container-description {
		height: 6rem;
		grid-column: 1 / 6;
	}
}