.appFooter {
    padding-top: 9rem;
    font-size: .9rem;
    
    display: grid;
    grid-template-columns: repeat(12, 1fr);
}

.appFooter>div {
    padding: .25rem;
}

/*--.appFooter Company Info ----*/
.appFooter .company-info {
    grid-column: 2 / 7;
}

/*--.appFooter Contact Info ----*/
.appFooter .contact-me {
    grid-column: 8/ -2;
    box-shadow: 0px 0px .5rem black;
    
    display: flex;
    flex-direction: column;
    align-items: center;
}
.appFooter .contact-me *{
    width: 100%;
}
.appFooter .contact-me header{
  padding: .75rem;
  font-size: 1.3rem;
}

.appFooter .contact-me:hover{
    box-shadow: 0px 0px 1rem black;
}
.appFooter .contact-me input[type="text"],
input[type="email"],
input[type="submit"],
textarea {
    font-size: 0.85rem;
    padding: .75rem;
}

.appFooter .contact-me 
input[type="submit"] {
    border-radius:  2rem !important;
}


.contact-me .thanks {
    cursor: default;
    border-radius: 0 0 2rem 2rem ;
    margin: 0;
    padding: 1rem;
}

/*--.appFooter Copyright----*/
.appFooter .footer-icon {
    grid-column: 1/ -1;
    text-align: center;
    padding: 0;
}

.appFooter .footer-icon img {
    margin: .4rem;
    margin-bottom: 0;
    cursor: pointer;
}

.appFooter .donate {
    grid-column: 1/-1;
}

.appFooter .donate img,
input[type=image] {
    width: 80px;
    filter: brightness(70%);
    padding-right: 10px;
}

.appFooter .donate img:hover,
input[type=image]:hover {
    filter: brightness(95%);
}

/*--.appFooter Copyright----*/
.appFooter .bottom {
    grid-column: 1/ -1;
    text-align: center;
    font-size: .85em;
    padding-top: 0;
    filter: brightness(70%);
    padding-bottom: 10px;
}

/* Medium devices (landscape tablets, 768px and up) */
@media (max-width: 768px) {
    .appFooter {
        padding-top: 2rem;
    }

    .appFooter .company-info {
        grid-column: 2 / -2;
    }

    .appFooter .contact-me {
        grid-column: 2/ -2;
        margin: 2rem 1rem;
    }
}

@media (min-width: 769px) and (max-width: 959px) {
    .appFooter .contact-me {
        grid-column: 8/ -2;
    }
}