.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  top: 0;
  position: sticky;
  z-index: 100;
  background: var(--navbar-bkg-color) !important;
  padding: .8rem 2rem 0 0;
}

.brand-title {
  margin: 0 2.5rem;
}

.toggle-button {
  display: none;
  padding: 1rem;
  width: 60px;
  cursor: pointer;
  margin: .5rem;
}

.navbar-links {
  display: flex;
  margin: 0;
}
.navbar-link {
  list-style: none;
  padding: 1.5rem 1.3rem;
}
/** <Link> gets rendered as a tag **/
.navbar-link a {
  text-decoration: none;
  font-size: .95rem;
  color: var(--navbar-font-color) !important;
  filter: brightness(120%);
}

.navbar-links li:hover a{
  color:  var( --navbar-bkg-color) !important;
}
.navbar-links li:hover {
  background-color: var( --navbar-font-color) !important;
}

.navbar-link>.blinking a {
  text-decoration: none;
  font-weight: 400;
  font-size: 1rem;
  color: #fcc221;
  animation: blinker 1s linear infinite;
}


@media (max-width: 768px) {
  .toggle-button {
    display: block;
  }
  .navbar-links {
    width: 100%;
    flex-direction: column;
  }
  .navbar-link {
    text-align: center;
    padding: .5rem 0;
  }
}