
.prime-day{
  /* background-image: url('../../images/primeday.webp');
  background-repeat: no-repeat;
  background-size: cover; */
  /* background-color: #3e85c7; */
}
.review-card {
  box-shadow: .25rem .25rem 1rem var(--color-shadow);
  border-radius: 1rem;
}
/* .review-card:hover {
  box-shadow: .25rem .25rem 1rem var(--color-primary);
} */
.my-review-card{
  /* box-shadow: .25rem .25rem 1rem #fcc221; */
  /* border-radius: 1rem; */
  border: none;
  filter: opacity(.95);
}
.my-review-card:hover{
  filter: opacity(1.1);
}
.caption1 {
  display: flex;
  gap: 2rem;
}

.caption {
  background: var(--color-unique);
  opacity: 0.9;
  color: black;
  display: flex;
  gap: 1rem;
}
.caption img{
  height: 100%;
  border-radius: .5rem;
}

.prime-day .simple-card {
  margin-top: 3rem;
  background: transparent;
}

.prime-day .simple-card section{
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  background-color: var(--color-tertiary);
  border-radius:0 0 2rem 2rem;
  gap: 10px;
}

.prime-day .simple-card section .simple-card section {
  margin: .25rem;
  border-radius:0 0 2rem 2rem;
}
.prime-day .simple-card section .simple-card header {
 font-size: 1.3rem;
}

.big-font-shadow {
  font-size: 1rem;
  font-weight: 500;
  text-shadow: 1px 1px 5px gray;
}

.prime-day .simple-card section .deal-price {
  width: 120px;
  margin-bottom: 2px;
  margin-top: 10px;

  padding: 10px;
  background: var(--color-red);
  border-radius: 1rem 1rem 0 0;

  font: small-caps 500 medium var(--app-font-family);
  text-shadow: .1rem .1rem .3rem var(--color-shadow);
  text-align: center;
  
  color: white;
}

@media (max-width: 599px) {
  .alert {
    grid-column: 1 / -1;
    height: 7rem;
  }
  .prime-day .container-description {
		height: 7rem;
  }
  .prime-day .container-description *{
    font-size: .9rem;
  }
  .prime-day .container-description img{
		height: 80%;
  }
  .prime-day .simple-card section{
    border-radius:0 0 1rem 1rem;
  }

.prime-day .simple-card section .simple-card section {
  margin: .25rem;
  border-radius:0 0 1rem 1rem;
}

.prime-day .simple-card section .simple-card header {
  font-size: 1.1rem;
 }
}