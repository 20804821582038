/************************************
          default Card
***********************************/

.simple-card{
	margin: 1rem;
	border-radius: 2rem;
	box-shadow: .01rem .01rem .15rem var(--simple-card-border-color);
	color: var(--simple-card-font-color) !important;
	background: var(--simple-card-background-color);
}
.simple-card:hover {
	box-shadow: .15rem .15rem 0.25rem var(--simple-card-border-color);
    filter: brightness(99%);
  }
.simple-card>header {
	margin: 0 0 .5rem 0;
	border-radius: 2rem 2rem 0 0;

	padding: 1rem;
	font: small-caps 500 1.7em var(--app-font-family);
	text-shadow: .1rem .1rem .3rem var(--color-shadow);	
	text-align: center;
	
	background-color: var(--simple-card-header-bkg-color);
	color: var(--simple-card-header-color);
}
.simple-card>footer{
	display: flex;
	justify-content: flex-end;
	align-items: end;
    margin: 0 1rem 1rem 0 ;
}
.simple-card>footer button{
	background-color: var(--simple-card-header-bkg-color);
	
	color: var(--simple-card-header-color);
    margin: 0 1rem 1rem 0 ;
	padding: .80rem;
}
.simple-card section{
	display: flex;
	padding: .5rem;
   }
 .simple-card section .desc{
	 margin: 1rem;
	 font-size: 1.1rem;
}

.simple-card-container {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(3, 4fr);
	gap: 1rem;
	padding: 0 2rem;
}
/** Phones **/
@media (max-width: 599px) {
	.simple-card {
		border-radius: 1rem;
	}
	.simple-card>header {
		border-radius: 1rem 1rem 0 0;
		font-size: 1.4rem;
	}
}

/** low res devices**/
@media (max-width: 768px) {
	.simple-card-container {
		grid-template-columns: repeat(1, 12fr);
		padding: 0;
	}
}
/** Small Computer screens( until 1200px then Big  Computer screens**/
@media (min-width: 769px) and (max-width: 1024px) {
	.simple-card-container {
		grid-template-columns: repeat(2, 6fr);
		gap: .25rem;
	}
}

/** Small Computer screens( until 1200px then Big  Computer screens**/
@media (min-width: 1024px) and (max-width: 1468px) {
	.simple-card-container {
		grid-template-columns: repeat(2, 6fr);

	}
}

/** *huge devices*/
@media (min-width: 1920px) {
	.simple-card-container {
		grid-template-columns: repeat(3, 4fr);
	}
}