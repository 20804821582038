:root {
    /** Don't directly use these absolute colors under this section in the code **/ 
      --color-red: #580202;
      --color-green: #002f06;
    
      --color-blue-dark: #0d2733;
      --color-blue-light: #004f6c;
      --color-cream:#e8eaf0;
    

      --color-primary:  rgb(30, 65, 93);
      --color-secondary: rgb(94, 115, 142);
      --color-tertiary: rgb(232, 233, 235);
}