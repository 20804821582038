.button,
button,
input[type="button"],
input[type="submit"] {
    font-size: .8em;
    text-align: center;

    padding: .6em 1.2em;
    cursor: pointer;

    border: 1px solid var(--color-secondary);
    border-radius: .3em;

    background-color: var(--color-tertiary);
    color: var(--color-secondary);
}

button:hover,
input[type="button"]:hover,
input[type="submit"]:hover {
    /* background-color: var(--color-primary);
    color: var(--app-background); */
    filter: brightness(130%) !important;
}

.round-button {
    border-radius: .9rem !important;
}

.primary-color-button {
    background: var(--color-primary) !important;
    color: var(--color-tertiary) !important;
}

.secondary-color-button {
    background: var(--color-secondary) !important;
    color: var(--color-tertiary) !important;
}
.tertiary-color-button {
    background: var(--color-tertiary) !important;
    color: var(--color-secondary) !important;
}

.unique-color-button {
    background: var(--color-unique) !important;
    color: var(--color-secondary) !important;
}
.red-button {
    background-color: var(--color-red) !important;
    color: var(--app-font-color) !important;
    filter: brightness(85%) !important;
}

input[type="text"],
input[type="email"],
textarea {
    border: none;
    background-color: transparent;
}