/************************************
          Override properties for simple card 
***********************************/

.blog-post .simple-card>header {
	font: small-caps 500 1.2em var(--app-font-family);
  padding: .5rem;
  height: 4rem; /* for double line title to come in same space as single line*/
}
.blog-post .simple-card>section .quote {
  font-size: .8rem;
}

.blog-post .simple-card>section img {
  width: 40%;
  filter: grayscale(50%);
  border-radius: 1rem;
  margin: 1rem;
  /* max-height: 300px !important; */
}
.blog-post .simple-card>section img:hover {
  filter: grayscale(20%);
}



@media (max-width: 599px) {
  .blog-post .simple-card>section img {
    margin: .75rem;
    width: 35%;
  }
}
@media (max-width: 768px) {
  .blog-post .simple-card>section img {
    margin: .75rem;
  }
}