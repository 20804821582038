@import url('./colors.css');

:root {

  /** --------- START  Theme -------------**/
  --app-background: var(--color-tertiary);
  --app-font-color: var(--color-primary);

  --navbar-bkg-color: var(--color-primary);
  --navbar-font-color: var(--color-tertiary);

  --home-header-color: var(--color-primary);
  /** --------- END  Theme ------------- **/


  /** --------------------------------------------**/
  /** simple card defaults for both dark and light themes **/
  /** --------------------------------------------**/
  --simple-card-header-bkg-color: var(--color-primary);
  --simple-card-header-color: var(--color-tertiary);

  --simple-card-background-color: linear-gradient(var(--color-secondary),var(--color-tertiary));  
  --simple-card-border-color: var(--color-primary);

  /*** --- END simple card defaults  -------------**/


--app-font-family: 'Roboto', sans-serif;
     /*   Fonts  use any one.
        --app-font-family:: 'Spectral', serif;
         --app-font-family: 'Mukta', sans-serif; 
          --app-font-family: 'Open Sans', sans-serif; 
        */

}