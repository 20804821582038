.tabs {
    display: flex;
    flex-wrap: wrap;
    padding: 1vh 1vw;
}

.tabs input[type="radio"] {
    display: none;
}

.tabs label {
    padding: 1.5vh 1.5vw;
    font-weight: bold;
    border-radius: 1px;
    background: var(--simple-card-header-bkg-color);
    color: var( --simple-card-header-color);
    opacity: 50%;

}

.tabs input+ label :hover {
    cursor: pointer;
}

.tabs .tab {
    width: 100%;
    padding: 3rem  2.5rem;

    order: 1;
    display: none;
}
.tabs input[type='radio']:checked+label+.tab {
    display: block;
}

.tabs input[type="radio"]:checked+label {
    opacity: 90%;
}



.tab li{
   margin-bottom: 0.5rem;
}   



.tab .clients {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.tab img {
    margin: 0 2vw;
}

@media (max-width: 768px) {
    .tabs .tab {
        padding: 25px;
        text-align: left;
    }
}