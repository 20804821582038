.about-me {
    margin: 1vh 0 1vh 0;
    padding: 4vh 2vw 0 2vw;
    border-radius: .5em;

    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(3, auto);
    justify-content: space-between;
    row-gap: 1vh ;

   
}
.about-me .profile-pic {
    grid-area: 1/1 / 2 / 3;
    /*-- This is to show image in circle --*/
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;

    /** This is needed for showing circle in center --*/
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin: auto;
}
.about-me .profile-pic img {
    width: 100%;
}


.about-me .quick-summary {
    grid-area: 2/1/3/3;
    text-align: center;
    font-size: 1.2rem;
}

.about-me .description {
    padding: 3vh 0 0 0;
    grid-area: 1 / 3/ 4/ 9;
}
.about-me .description>h3 {
    font: 600 xx-large var(--app-font-family);
}
.about-me .description .self-summary {
    margin-top: 3vh;
    padding: 1vh 0vw;
}
.about-me .description .self-summary .tabs .tab ul{
    padding-inline-start: 1rem;
}
.about-me .description .self-summary .tabs .tab ul li{
    padding-bottom: 1.5rem;
}

@media (max-width: 768px) {
    .about-me {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(auto, auto);
        text-align: center;
        row-gap: 1vh ;
    }
    .about-me .profile-pic {
        grid-area: 1/1 / 2 / 2;
    }
    .about-me .quick-summary {
        grid-area: 2/1 / 3 / 2;
    }
    .about-me .description {
        grid-area: 3/1/4/2;
    }
}
@media (min-width: 768px) and (max-width: 1200px){
    .about-me .description {
        grid-area: 1 / 3/ 4/ 10;
    }
}