.word-cloud {
	width: 55%;
    background: radial-gradient(var(--color-secondary), var(--color-primary));
	margin: 1.5rem 0px 3rem 0px;
}
.word-cloud>header {
	padding: 1rem !important;
	font-size: 1.4rem !important;
}

@media (max-width: 599px) {
	.word-cloud  {
		width: auto;
	}
}