.zigzag-container {
	width: 100%;

	display: flex;
	flex-direction: column;
	gap: 5rem;
}

.zigzag-container>:nth-child(odd) {
	align-self: flex-start;
}
.zigzag-container>:nth-child(even) {
	align-self: flex-end;
}