@import url('./Header.css');

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/*********************
.home>content
**********************/
.home>.content {
  padding: 0 .5rem;
  padding-bottom: 5rem;
  width: 100%;
}

.home>.content .simple-card section img{
  width: 100px;
  height: 100px;
  padding: .5rem;
  margin: .25rem 1rem;
}
@media (max-width: 599px) {
  .home>.content {
    padding-bottom: 3rem;
  }
}
/** Small Computer screens**/
@media (max-width: 1024px) {
  .home>.content .simple-card section img{
    width: 95px;
    height: 95px;
    margin:  1rem;
  }
}
@media (min-width: 1920px) {
  /** default behaviour is 4 cards on bigger screens
   I'm overriding it to keep maximum 3 card*/
  .home>.content .simple-card-container {
		grid-template-columns: repeat(3, 4fr);
	}
}

