@import url('./themes/theme.css');
@import url(./common/style/containers.css);
@import url(./common/style/simple-card.css);
@import url(./common/style/masonry.css);
@import url(./common/style/inputs.css);

body {
  align-items: center;
  margin: 0px auto;

  font-family: var(--app-font-family) !important;
  /* line-height: 1.5; */
  line-height: 1.6;

  background: var(--app-background);
  color: var(--app-font-color);
}


*,
*::before,
*::after {
  box-sizing: border-box;
}

img {
  /*--copied following properties from internet
 to disable drag. not sure which one works ---*/
  -webkit-user-drag: none;
  /* Chrome all / Safari all */
  -khtml-user-drag: none;
  -moz-user-drag: none;
  /* Firefox all */
  -o-user-drag: none;
  user-drag: none;

  /*--copied following properties from internet
 to disable click. not sure which one works ---*/
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  -ms-user-select: none;
  /* IE 10+ */
  -o-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  /* to disable iphone force touch*/
}

.content-area {
  /* max-width: 1860px;  */
  margin: auto;
  width: 95%;
}

.quote {
  font-size: 0.9em;
  font-style: italic;
  text-align: right;
  opacity: 90%;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.pointer:hover {
  cursor: pointer;
}

.img-xs {
  width: 15px;
}

.img-s {
  width: 35px;
  height: 30px;
}

.img-m {
  width: 70px;
}

.img-l {
  width: 110px;
}

.img-xl {
  width: 150px;
}

.img-xxl {
  width: 250px;
}

.circle {
  /*-- make sure width and hight are set as same. --*/
  border-radius: 50%;
  overflow: hidden;
  /* box-shadow: 5px 5px 5px; */
}

.no-decoration {
  text-decoration: none !important;
}

.small-text {
  font-size: small;
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}


@media (max-width: 599px) {
  .App>* {
    padding: 0;
    background: border-box;
  }
}

@media (min-width: 600px) and (max-width: 1919px) {
  .App>* {
    padding-left: 1vw;
    padding-right: 1vw;
    background: border-box;
  }
}

/** enforcing content to stay in 1920px area **/
@media (min-width: 1920px) {
  .App>* {
    padding-left: calc((100vw - 1920px)/2);
    padding-right: calc((100vw - 1920px)/2);
    background: border-box;
  }
}