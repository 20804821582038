:root {
  --padding: 1.5rem 5vw;
  --width: 85%;
  --small-screen-width: 95%;
}

.search-posts {
  grid-column: -3 / -1;
  align-self: end;
  padding: 0 5vw;
}

.search-posts input {
  width: 100%;
  height: 3rem;
  font-size: 1em;
  border: none;
}


/*------ actual post content -------*/
.post {
  width: var(--width);
}

.post>* {
  padding: var(--padding);
  box-shadow: .25rem .25rem 5px var(--color-secondary);
}

.post header{
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  padding: 1.5rem !important;
  height: 5rem !important;
}

.post .content * {
/* if I don't re declare font-family here, 
  safari doesn't take the correct font size on post code area, 
 chrome works without  it. taking from parent container*/
  color: var(--app-font-color) !important;
  max-width: 100%;
  font-size: 1rem !important;
}

.post .content  h1, h1>span {
  font-size: 1.3rem !important;
}
.post .content  h2, h2>span {
  font-size: 1.15rem !important;
}
.post .content blockquote {
  /* box-shadow: 3px 0 0 0 var(--app-font-color) inset !important; */
}


element.style {

  box-sizing: inherit;
  margin-bottom: 0px;
  margin-top: 56px;
  overflow-x: auto;
  padding: 20px;
}
*, *::before, *::after {
  box-sizing: border-box;
}

/** Flip the background and font colors for highlighted text   **/
.post .content code {
  color: var(--app-background) !important;
  background-color: var(--app-font-color) !important;
  font-size: .85em !important;
}

.post .content code * {
  color: var(--app-background) !important;
  background-color: var(--app-font-color) !important;
  font-size: .85em !important;
}


.post .content pre {
    margin: 2rem 20%  1rem 5% !important;
    border-radius: 5px;
}

/** Flip the background and font colors for content in preformatted blocks  **/
.post .content pre *{
  color: var(--app-font-color) !important;
  background-color:var(--app-background) !important ;
 
  font-size: .90em !important;
  font-family: 'Courier New', Courier, monospace !important;
}



/* Medium devices (landscape tablets, 768px and up) */
@media (max-width: 599px) {
  .search-posts {
    grid-column: 1 / -1;
  }
  .post {
    width: var(--small-screen-width);
  }
  .blog-post>.inner-container>.cards-container {
    padding:  1rem !important;
  }
  .post .content pre {
    margin: 2rem 1rem !important;
  }

  .post .title {
    font-size: 1.4rem;
  }
  .post .content * {
      font-size: .95rem !important;
    }

}

@media (min-width: 600px) and (max-width: 768px) {
  .post {
    width: var(--small-screen-width);
  }
  .post .content pre {
    margin: 1rem !important;
  }
}