/** Enhancing the existing css classes of the react component **/



.react-tabs__tab-list {
    border-bottom: none !important;
    margin: 0 0 1px !important;
}

.react-tabs__tab {
    font-weight: 500;
    border-radius: 7px 7px 0 0 !important;
    margin: 0 .5px !important;

    font-size: .9rem;
    box-shadow: inset 0px 0px 15px var(--color-primary);
}

.react-tabs__tab--selected {
    box-shadow: inset 0px 0px 5px var(--color-primary);
}

/** Second level tabs **/
.react-tabs__tab-panel--selected 
.react-tabs__tab {
    font-size: .8rem;
    background: var(--color-tertiary) !important;
    box-shadow: inset 0px 0px 20px var(--color-primary-red);
}

/** Second level tabs **/
.react-tabs__tab-panel--selected 
.react-tabs__tab--selected {
    box-shadow: inset 0px 0px 5px var(--color-primary-red);
}

/** Third level tabs **/
.react-tabs__tab-panel--selected 
.react-tabs__tab-panel--selected 
.react-tabs__tab {
    font-size: .75rem;
    background: none !important;
    box-shadow: inset 0px 0px 15px var(--color-shadow);
}

.react-tabs__tab-panel--selected 
.react-tabs__tab-panel--selected 
.react-tabs__tab--selected {
    box-shadow: inset 0px 0px 5px var(--color-shadow);
}


.react-tabs__tab-panel {
    display: none;
    padding-left: 2px;
}

.react-tabs__tab-panel--selected {
    display: block;
    border: none;
}

.react-tabs__tab-panel--selected 
.react-tabs__tab-panel--selected 
.react-tabs__tab-panel--selected {
    font-size: small;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    border: .05px solid var(--color-shadow);
    box-shadow: inset 0px 0px 15px var(--color-secondary);
    padding: 20px;
}


@media (min-width: 599px) {
.react-tabs__tab-panel--selected 
.react-tabs__tab-panel--selected 
.react-tabs__tab-panel--selected {

    justify-content: space-evenly;

}
}